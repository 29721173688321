import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import ProudCoderSvg from "../images/undraw/undraw_proud_coder_7ain.svg";
import LogoCloud from "../components/LogoCloud";

const RubyOnRailsNewPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
            previousUrl="/ruby-on-rails-new"
            nextUrl="/ruby-on-rails-new-3"
          >
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faClone}
                  size="2x"
                  className="text-magenta-900"
                />
                <p className="ml-5 text-base leading-6 text-gray-700 text-left mb-3">
                  <strong>less code duplication</strong> generally leads to
                  fewer defects that are easier to fix, faster adding of new
                  features, and <strong>general higher robustness</strong> of
                  your application
                </p>
              </div>
              <figure className="w-full">
                <img alt="proud coder" src={ProudCoderSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "weavs",
                url: "https://www.weavs.io",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsNewPage;
